import { useState } from 'react';
import { ModalOverlay, ModalContent, CloseButton, FileInput, Button, LoaderContainer, Loader } from './styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Modal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  if (!isOpen) return null;

  const handleFileDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type.startsWith('image/')) {
      setFile(droppedFile);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setFile(selectedFile);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleSubmit = async () => {
    if (file) {
      setIsLoading(true); // Inicia o estado de carregamento

      const formData = new FormData();
      formData.append('image', file);
      try {
        const result = await axios.post('https://apimouthcheck.andersonlbsoares.com.br/avaliar/pytorch', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        let prediction = result.data.prediction;
        const probability = result.data.probability;
        if (prediction === 1) {
          prediction = "Normal";
        } else {
          prediction = "Câncer";
        }
        const reader = new FileReader();
        reader.onloadend = () => {
          // Navegar após a conversão da imagem
          navigate('/results', {
            state: {
              prediction: prediction,
              probability: probability,
              image: reader.result // imagem em Base64
            }
          });
        };
        reader.readAsDataURL(file);
        
      } catch (error) {
        alert('Ocorreu um erro ao processar sua imagem. Tente novamente.');
      } finally {
        setIsLoading(false); // Finaliza o estado de carregamento
        setFile(null);
        onClose();
      }
    } else {
      alert('Nenhuma imagem selecionada!');
    }
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent
        onClick={e => e.stopPropagation()}
        onDrop={handleFileDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        style={{ border: isDragging ? '2px dashed #007bff' : 'none' }}
      >
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <h2 style={{ marginBottom: '10px' }}>Envie uma Imagem</h2>
        <p style={{ marginBottom: '20px', color: '#555' }}>Selecione uma imagem para enviar:</p>
        <FileInput type="file" accept="image/*" onChange={handleFileChange} />
        
        {isLoading ? (
          <LoaderContainer>
            <Loader /> {/* Estilize esse Loader como um círculo de carregamento */}
            <p>Processando sua imagem...</p>
          </LoaderContainer>
        ) : (
          <div style={{ marginTop: '20px' }}>
            <Button onClick={handleSubmit}>Enviar</Button>
          </div>
        )}
        
        {file && <p>Arquivo selecionado: {file.name}</p>}
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
